import React, { useEffect } from 'react';
import { AppContainer } from './AppContainer';
import {NavBarMain} from './NavBar';
import Typewriter from 'typewriter-effect';
import DemoPic from './hero-image.png';
import Product from './product.png';
import search from './search.gif';
import SignatureStyle from "./components/SignatureStyle";
import Image from './testimonial-01.jpg';
import TestimonialsImage02 from './testimonial2.png';
import TestimonialsImage03 from './testimonial3.png';
const IMAGE_ANIMATION_DURATION = 8;
const TYPE_ANIMATION_DURATION = 1;
const LEFT_ANIMATION_DURATION = 1;

const figmaLink = 'https://www.figma.com/community/plugin/1145446664512862540'

function Interface({
    prompt,
}: {
    title?: string,
    prompt: string,
}) {
    return (
        <div className='relative'>
            <div className='relative flex flex-row  rounded-lg overflow-hidden z-10 opacity-80 outline outline-1 outline-zinc-800/80'>
                <div 
                    className='relative w-[750px]'
                >
                    <div 
                        className='absolute z-10 text-zinc-300'
                        style={{
                            width: '140px',
                            top: '88px',
                            left: '45px',
                        }}
                    >
                        <div
                            className='h-[108.5px]'
                        >
                            <div
                                className='w-full text-left text-xs mx-4'
                            >
                                <Typewriter
                                    options={{
                                        strings: [prompt],
                                        autoStart: true,
                                        delay: 15,
                                        //@ts-ignore
                                        deleteSpeed: 10,
                                        // @ts-ignore
                                        pauseFor: 5000,
                                    }}
                                />
                            </div>
                        </div>
                       
                       
                    </div>
                    <img 
                        alt="screenshot" 
                        src={DemoPic}
                        className='hero-shadow-inner'
                        style={{
                            opacity: 1.0,
                        }}
                    />
                </div>
            </div>
            <div 
                className='absolute w-[750px] h-[30px] gradient-shadow z-0 animate-scale'
                style={{
                    top: '0px'
                }}
            />
        </div>
    )
}

function GreenJuice({
    title,
    prompt,
}: {
    title?: string,
    prompt: string,
}) {
    return (
        <div className='relative'>
            <div className='relative flex flex-row bg-zinc-900 rounded-lg overflow-hidden z-10 opacity-80 outline outline-1 outline-zinc-800/80'>
                <div 
                    className='relative w-[150px]'
                >
                    
                    <img 
                        alt="screenshot" 
                        src={Product}
                        className='hero-shadow-inner'
                        style={{
                            opacity: 0.9,
                        }}
                    />
                </div>
            </div>
            <div 
                className='absolute w-[150px] h-[250px] gradient-shadow z-0 animate-scale'
                style={{
                    top: '0px'
                }}
            />
        </div>


    )
}



function PromptRec({
        title,
    prompt,
}: {
    title?: string,
    prompt: string,
}) {
    return (

        <div className='relative'>
            <div className='text-3xl title-font text-zinc-200 pb-[80px]' >Prompt <span className='text-lime-500'>Recommendations</span></div>
            <div className='relative flex flex-row bg-zinc-900 rounded-lg overflow-hidden z-10 opacity-80 outline outline-1 outline-zinc-800/80'>
                <div 
                    className='relative w-[350px]'
                >
                    
                    <img 
                        alt="screenshot" 
                        src={search}
                        className='hero-shadow-inner'
                        style={{
                            opacity: 0.9,
                        }}
                    />
                </div>

            </div>

            <div 
                className='absolute w-[400px] h-[180px] gradient-shadow z-0 animate-scale mt-[160px]'
                style={{
                    top: '0px'
                }}
            />
        </div>



    )
}


const heroImages = [
    {
        prompt: 'Green juice bottle surrounded by limes.',
        leftBackground: 'url("https://ik.imagekit.io/mfowj2lm8/lime1_TGESXPMla?ik-sdk-version=javascript-1.4.3&updatedAt=1669166821364")',
        rightBackground: [
             'url("https://ik.imagekit.io/mfowj2lm8/limes2_jI5UsyAmE?ik-sdk-version=javascript-1.4.3&updatedAt=1669167365980")',
            'url("https://ik.imagekit.io/mfowj2lm8/limes3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669222517448")',
        ],
    },
    {
        prompt: 'Green juice bottle surrounded by plants',
        leftBackground: 'url("https://ik.imagekit.io/mfowj2lm8/houseplant2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669167180304")',
        rightBackground: [
            'url("https://ik.imagekit.io/mfowj2lm8/houseplant1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669167168826")',
            'url("https://ik.imagekit.io/mfowj2lm8/houseplant3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669167180224")',
        ]
    },
    {
        prompt: 'Green juice bottle on a mossy rock.',
        leftBackground: 'url("https://ik.imagekit.io/mfowj2lm8/greenjuice.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669167856361")',
        rightBackground: [
        'url("https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_17.50.02_-_green_juice_bottle_standing_on_a_mossy_rock.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177161918")',
        'url("https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_17.50.02_-_green_juice_bottle_standing_on_a_mossy_rock.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177161918")',
        ]
    },
    {
        prompt: 'green juice bottle next to vegetables',
        leftBackground: 'url("https://ik.imagekit.io/mfowj2lm8/Screen_Shot_2022-11-21_at_4.09.58_PM_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669190034625")',
        rightBackground: [
            'url("https://ik.imagekit.io/mfowj2lm8/Screen_Shot_2022-11-21_at_3.54.31_PM_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669190034699")',
            'url("https://ik.imagekit.io/mfowj2lm8/Screen_Shot_2022-11-21_at_4.10.24_PM_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669190103073")'
        ]
    },
    
]



function RightVerticalImageSet({
    imageIndex,
}: {
    imageIndex: number,
}) {
    const images = heroImages[imageIndex].rightBackground;
    return (
        <div className='flex flex-col z-1 h-[512px] ml-[485px]  mt-[-350px]'
        >
            {
                Array.isArray(images)
                ? images.map((background, index) => (
                    <div 
                        key={`${imageIndex}_${index}`}
                        className='w-[140px] h-[140px] bg-zinc-100 z-50 rounded-lg shadow-lg my-2 left: 200px'
                        style={{
                            background,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}


                    />
                ))
                :   <div 
                        key={imageIndex}
                        className='w-[140px] h-[140px] bg-zinc-100 z-50 rounded-lg shadow-lg'
                        style={{
                            background: images,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    />
            }
        </div>
    )
}



function RightVerticalImageSet2({
    imageIndex,
}: {
    imageIndex: number,
}) {
    const images = heroImages[imageIndex].rightBackground;
    return (
        <div className='flex flex-col z-0 h-[512px] '>
            {
                  <div 
                        key={imageIndex}
                        className='w-[186px] h-[186px] bg-zinc-100 z-50 rounded-lg shadow-lg mt-[150px]'
                        style={{
                            // @ts-ignore
                            background: images,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    />
            }
        </div>
    )
}


function Partners() {
  return (
   <div>
            <div className="dark:bg-jacarta-800 bg-light-base">
                <div className="container">
                   
                            <a 
                            href={figmaLink}
                            className='block w-[310px] rounded-md text-xs font-semibold py-2 bg-lime-500 hover:bg-lime-400 transition-colors text-zinc-800 select-none cursor-pointer'
                        >
                            Render
                        </a>
                       
                    
                </div>
            </div>
        </div>
  );
}

function Testimonials() {
  return (
    <section >
     
       
          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none mt-[-180px] pb-[50] ">
            {/* 1st Testimonial */}
            <SingleTestimonial/>
            {/* 2nd Testimonial */}
           <SingleTestimonial2/>
            {/* 3rd Testimonial */}
           <SingleTestimonial3/>
          </div>
        
    
    </section>
  );
}

function SingleTestimonial() {
  return (
    <section data-aos="fade-up">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <div className="relative inline-flex mb-4">
              <img className="rounded-full inline-flex" src={Image} width="35" height="35" alt="Testimonial 04" />
              <svg className="absolute top-0 left-full -ml-2 fill-lime-500" width="20" height="18" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 18h6.196L9.448 0H3.252L0 18Zm10.552 0h6.196L20 0h-6.196l-3.252 18Z" />
              </svg>
            </div>
            <div className="text-sm text-slate-500 italic mb-3">
              "Flair is 10x faster and 10x cheaper."
            </div>
            <div className="text-slate-500 font-small">
              <a className="text-slate-300">My First Million Pod</a> -{' '}
              <a className="text-lime-500" href="#0">
                Shaan Puri
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function SingleTestimonial2() {
  return (
    <section data-aos="fade-up">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <div className="relative inline-flex mb-4">
              <img className="rounded-full inline-flex" src={TestimonialsImage02} width="35" height="35" alt="Testimonial 04" />
              <svg className="absolute top-0 left-full -ml-2 fill-lime-500" width="20" height="18" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 18h6.196L9.448 0H3.252L0 18Zm10.552 0h6.196L20 0h-6.196l-3.252 18Z" />
              </svg>
            </div>
            <div className="text-sm text-slate-500 italic mb-3">
              "Not only does Flair capture the essence of each drink, but they look pretty amazing too.""
            </div>
            <div className="text-slate-500 font-small">
              <a className="text-slate-300">Kava Social</a> -{' '}
              <a className="text-lime-500" href="#0">
                Zoe
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function SingleTestimonial3() {
  return (
    <section data-aos="fade-up">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <div className="relative inline-flex mb-4">
              <img className="rounded-full inline-flex" src={TestimonialsImage03} width="35" height="35" alt="Testimonial 04" />
              <svg className="absolute top-0 left-full -ml-2 fill-lime-500" width="20" height="18" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 18h6.196L9.448 0H3.252L0 18Zm10.552 0h6.196L20 0h-6.196l-3.252 18Z" />
              </svg>
            </div>
            <div className="text-sm text-slate-500 italic mb-3">
              "My Flair posts sell faster than my own product photography! Flair posts tell a better story."
            </div>
            <div className="text-slate-500 font-small">
              <a className="text-slate-300">Frickery Frackery Pottery</a> -{' '}
              <a className="text-lime-500" href="#0">
                Tracy
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


function DesignInterface() {
    const [imageIndex, setImageIndex] = React.useState(0);
    useEffect(() => {
        const imageIntervalId = setInterval(() => {
            setImageIndex(index => (index + 1) % heroImages.length)
        }, IMAGE_ANIMATION_DURATION * 1000);
        return () => {
            clearInterval(imageIntervalId);
        }
    }, []);
    return (

        <div className='items-center my-20'>
            <div 
                key={`${imageIndex}-left`}
                className=' items-center mb-32'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - TYPE_ANIMATION_DURATION}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION}s`
                }}
            >
                <div 
                    className='w-[140px] h-[140px] bg-zinc-100 z-99999 rounded-lg shadow-lg ml-[330px] mb-[-390px]'
                    style={{
                        background: heroImages[imageIndex].leftBackground,
                        backgroundSize: 'cover',
                    }}
                />
               
            </div>
            <Interface
                prompt={heroImages[imageIndex].prompt}
                title={`${imageIndex} / ${heroImages.length}`}
            />
            <div
                key={`${imageIndex}-right`} 
                className='flex flex-row items-center'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - (TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION)}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION}s`
                }}
            >
                <RightVerticalImageSet imageIndex={imageIndex}/>

            </div>


        </div>

    )
}
function ProductPhotography() {
    const [imageIndex, setImageIndex] = React.useState(0);
    useEffect(() => {
        const imageIntervalId = setInterval(() => {
            setImageIndex(index => (index + 1) % heroImages.length)
        }, IMAGE_ANIMATION_DURATION * 1000);
        return () => {
            clearInterval(imageIntervalId);
        }
    }, []);
    return (
        <div>
        <div className='text-3xl title-font text-zinc-200 mt-[80px]' >Generate entire <span className='text-lime-500'>Photoshoots </span>in less than a minute.</div>
        <div className='flex flex-row items-center mb-[-100px]'>

            <div 
                key={`${imageIndex}-left`}
                className='flex flex-row items-center mt-[-50px] pb-[-100px]'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - TYPE_ANIMATION_DURATION}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION}s`
                }}
            >
                <div 
                    className='w-[186px] h-[186px] bg-zinc-100 z-50 rounded-lg shadow-lg mb-20'
                    style={{
                        background: heroImages[imageIndex].leftBackground,
                        backgroundSize: 'cover',
                    }}
                />
                <svg width="120" height='125' viewBox='0 0 100 100'>
                    <path className='hero-line' stroke='#bef264' fill='none' strokeWidth="1" d="M 0 0 L 30 0 A 20,20 45 0 1 50 20 L 50 80 A 20,20 45 0 0 70 100 L 100 100"/>
                    <path stroke='#84cc16' fill='none' opacity={0.8} strokeWidth="2" d="M 0 0 L 30 0 A 20,20 45 0 1 50 20 L 50 80 A 20,20 45 0 0 70 100 L 100 100"/>
                </svg>
            </div>
            <GreenJuice
                prompt={heroImages[imageIndex].prompt}
                title={`${imageIndex} / ${heroImages.length}`}
            />
            <div
                key={`${imageIndex}-right`} 
                className='flex flex-row items-center'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - (TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION)}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION}s`
                }}
            >
                <svg width="120" height="125" viewBox='0 0 100 100'>
                    <path className='hero-line' stroke='#bef264' strokeWidth="1" d="M0 50 L100 50"/>
                    <path stroke='#84cc16' opacity={0.8} strokeWidth="2" d="M0 50 L100 50"/>
                </svg>
                <RightVerticalImageSet2 imageIndex={imageIndex}/>
            </div>
        </div>
<div className='text-xl my-2 text-zinc-500  pb-[100px]'>
                    Shoot your product anywhere. We preserve your brand's details. 
                </div>
        </div>
    )

}

function SmartPrompt() {
    const [imageIndex, setImageIndex] = React.useState(0);
    useEffect(() => {
        const imageIntervalId = setInterval(() => {
            setImageIndex(index => (index + 1) % heroImages.length)
        }, IMAGE_ANIMATION_DURATION * 1000);
        return () => {
            clearInterval(imageIntervalId);
        }
    }, []);
    return (
        <div className='flex flex-row items-center my-20'>
            <div 
                key={`${imageIndex}-left`}
                className='flex flex-row items-center mb-32'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - TYPE_ANIMATION_DURATION}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION}s`
                }}
            >
                <div 
                    className='w-[156px] h-[156px] bg-zinc-100 z-50 rounded-lg shadow-lg mb-20'
                    style={{
                        background: heroImages[imageIndex].leftBackground,
                        backgroundSize: 'cover',
                    }}
                />
                <svg width="120" height='125' viewBox='0 0 100 100'>
                    <path className='hero-line' stroke='#bef264' fill='none' strokeWidth="1" d="M 0 0 L 30 0 A 20,20 45 0 1 50 20 L 50 80 A 20,20 45 0 0 70 100 L 100 100"/>
                    <path stroke='#84cc16' fill='none' opacity={0.8} strokeWidth="2" d="M 0 0 L 30 0 A 20,20 45 0 1 50 20 L 50 80 A 20,20 45 0 0 70 100 L 100 100"/>
                </svg>
            </div>
            <PromptRec
                prompt={heroImages[imageIndex].prompt}
                title={`${imageIndex} / ${heroImages.length}`}
            />
            <div
                key={`${imageIndex}-right`} 
                className='flex flex-row items-center'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - (TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION)}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION}s`
                }}
            >
                <svg width="120" height="125" viewBox='0 0 100 100'>
                    <path className='hero-line' stroke='#bef264' strokeWidth="1" d="M0 50 L100 50"/>
                    <path stroke='#84cc16' opacity={0.8} strokeWidth="2" d="M0 50 L100 50"/>
                </svg>
                <RightVerticalImageSet2 imageIndex={imageIndex}/>
            </div>
        </div>
    )
}

export function Home() {
    return (
        <AppContainer>
            <NavBarMain/>
            <div
                className='w-full flex flex-col justify-center items-center'
            >
                <div className='text-3xl title-font text-zinc-200'>Create with <span className='text-lime-500'>Flair</span>,</div>
                <div className='text-5xl my-6 title-font'>the <span className='text-lime-500'>AI Design Tool</span> for Branded Content.</div>
                <div className='text-xl my-2 text-zinc-500 pb-[100px]'>
                    Generate high quality marketing assets in seconds.
                </div>
                <DesignInterface/>
                <Testimonials/>
                <ProductPhotography/>
                <SignatureStyle/>
               <SmartPrompt/>
               <form id="contact-form" action="https://formspree.io/f/mbjbeldb"
  method="POST">
     <div className='text-3xl title-font text-zinc-200 mt-[-50px]' >Join our <span className='text-lime-500'>Waitlist</span></div>
      <div className="flex space-x-7 mt-[60px]">

      <div className="mb-6 w-1/2 ">
          <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
            Name<span className="text-red">*</span>
          </label>
          <input
            name="name"
            className="contact-form-input dark:bg-zinc-700 border-zinc-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="name"
            type="text"
            required
          />
        </div>

        <div className="mb-6 w-1/2">
          <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
            Brand/Organization<span className="text-red">*</span>
          </label>
          <input
            name="name"
            className="contact-form-input dark:bg-zinc-700 border-zinc-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="name"
            type="text"
            required
          />
        </div>

        <div className="mb-6 w-1/2">
          <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
            Work Email<span className="text-red">*</span>
          </label>
          <input
            name="email"
            className="contact-form-input dark:bg-zinc-700 border-zinc-700 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="email"
            type="email"
            required
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
          Message<span className="text-red">*</span>
        </label>
        <textarea
          id="message"
          className="contact-form-input bg-zinc-700 border-jacarta-700 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
          required
          name="message"
          rows={5}

        ></textarea>
      </div>
      <button
       type="submit"
        className="bg-accent shadow-accent-volume hover:bg-accent-dark bg-lime-500 hover:bg-lime-400 transition-colors text-zinc-800 rounded-full py-3 px-8 text-center font-semibold text-zinc-800 transition-all "
        id="contact-form-submit"
      >
        Submit
      </button>

      <div
        id="contact-form-notice"
        className="relative mt-4 hidden rounded-lg border border-transparent p-4 pb-[100px]"
      ></div>
    </form>
            </div>
        </AppContainer>
    )
}