export const hero_6_data = [
  {
    id: '0Amazing NFT art',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_18.33.32_-_bottle_emerging_out_of_nature__leaves__and_white_flowers__F20_zen.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177161854',
    title: '',
    authorName: '',
  },
  {
    id: '1Amazing NFT art',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_18.33.50_-_bottle_emerging_out_of_nature__leaves__white_flowers__zen_F20.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177161633',
    title: '',
    authorName: '',
  },
  {
    id: '2Punkie Cat',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_18.38.24_-_bottle_emerging_out_of_nature__leaves__white_flowers__zen_F20_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177160746',
    title: '',
    authorName: '',
  },
  {
    id: '3VR Space_283',
    img: 'https://ik.imagekit.io/mfowj2lm8/flowers2_NUOkWz0qj?ik-sdk-version=javascript-1.4.3&updatedAt=1669177933968',
    title: '',
    authorName: '',
  },
  {
    id: '4TSARÉVNA',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_20.33.36_-_bottle_surrounded_by_nature__leaves__and_white_flowers__zen_F20.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669178065418',
    title: '',
    authorName: '',
  },
  {
    id: '5Metasmorf',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_18.32.39_-_bottle_emerging_out_of_nature__leaves__white_flowers__zen_F20_cleanup_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177159390',
    title: '',
    authorName: '',
  },
  {
    id: '6VR Space_286',
    img: 'https://ik.imagekit.io/mfowj2lm8/1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177156385',
    title: '',
    authorName: '',
  },
  {
    id: '7PankySkal',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_19.59.49_-_bottle_emerging_out_of_nature__leaves__white_flowers__zen_F20_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177158929',
    title: '',
    authorName: '',
  },
  {
    id: '8VR Space_287',
    img: 'https://ik.imagekit.io/mfowj2lm8/DALL_E_2022-11-22_19.50.20_-_bottle_surrounded_by_nature__leaves__and_white_flowers__zen_F20_cleanup.png?ik-sdk-version=javascript-1.4.3&updatedAt=1669177160149',
    title: '',
    authorName: '',
  },
];
