
import React from "react";
import { hero_6_data } from './coverflow_data';


export default function SignatureStyle() {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="relative pl-[140px] pr-[140px]">
      <div className='text-3xl title-font text-zinc-200 pb-[130px] ' >Generate content in your brand's <span className='text-lime-500'>Signature Style</span>.</div>
        <div className="flex flex-col gap-7 lg:flex-row mt-[-80px]">
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {hero_6_data.slice(0, 4).map((item) => {
                const { id, title, img, authorName } = item;
                const itemLink = img
                  .split('/')
                  .slice(-1)
                  .toString()
                  .replace('_square.jpg', '')
                  .replace('.gif', '');
                return (
                  <article key={id}>
                    <div className="relative overflow-hidden rounded-lg bg-white dark:bg-jacarta-700">
                      <figure className="relative">
                       
                          <a className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                            <img
                              src={img}
                              alt={title}
                              className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                              height="470"
                              width="470"
                            />
                          </a>
                      </figure>
                      <div className="pointer-events-none absolute bottom-0 w-full p-5">
                        <h2 className="font-display text-base leading-none text-white xl:text-lg">
                          {title}
                        </h2>
                        <span className="text-2xs text-white">{authorName}</span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            {hero_6_data.slice(4, -4).map((item) => {
              const { id, title, img, authorName } = item;
              const itemLink = img
                .split('/')
                .slice(-1)
                .toString()
                .replace('.jpg', '')
                .replace('_square', '')
                .replace('.gif', '');
              return (
                <article key={id}>
                  <div className="relative overflow-hidden rounded-lg bg-white dark:bg-jacarta-700">
                    <figure className="relative">
                      
                        <a className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                          <img
                            src={img}
                            alt={title}
                            className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                            height="470"
                            width="470"
                          />
                        </a>
                    </figure>
                    <div className="pointer-events-none absolute bottom-0 w-full p-5">
                      <h2 className="font-display text-base leading-none text-white xl:text-lg">
                        {title}
                      </h2>
                      <span className="text-2xs text-white">{authorName}</span>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
          <div className="w-full lg:w-1/3">
            <div className="grid grid-cols-2 grid-rows-2 gap-5">
              {hero_6_data.slice(5, 9).map((item) => {
                const { id, title, img, authorName } = item;
                const itemLink = img
                  .split('/')
                  .slice(-1)
                  .toString()
                  .replace('.jpg', '')
                  .replace('_square', '')
                  .replace('.gif', '');
                return (
                  <article key={id}>
                    <div className="relative overflow-hidden rounded-lg bg-white dark:bg-jacarta-700">
                      <figure className="relative">
                       
                          <a className="group block after:absolute after:inset-0 after:block after:bg-jacarta-900/20">
                            <img
                              src={img}
                              alt={title}
                              className="w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105"
                              height="470"
                              width="470"
                            />
                          </a>
                      </figure>
                      <div className="pointer-events-none absolute bottom-0 w-full p-5">
                        <h2 className="font-display text-base leading-none text-white xl:text-lg">
                          {title}
                        </h2>
                        <span className="text-2xs text-white">{authorName}</span>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>

          </div>

        </div>
        <div className='text-xl my-2 text-zinc-500  mt-[30px]'>
                    Users can choose from our library of high-end styles, or create their own custom moodboard to generate images in their brand's signature aesthetic. 
                </div>
        
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};